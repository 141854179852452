import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source" ]
  connect() {
  }

  copy() {
    console.dir(this.sourceTarget)
    navigator.clipboard.writeText(this.sourceTarget.value);
  }
}
